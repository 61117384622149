@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap);
.quote-style {
  color: '#f8f8f2';
  background-color: '#272822';
  tab-size: 4;
}

.nav-link, #content a {
  color: #dc3545;
  text-decoration: none;
}
  .nav-link *, #content a * {
    color: #dc3545;
  }
  .nav-link:hover, #content a:hover {
    color: #950101;
    text-decoration: underline;
  }

.navbar-brand, #footer a {
  color: #dbdbdb;
  text-decoration: none;
}
  .navbar-brand *, #footer a * {
    color: #dbdbdb;
  }
  .navbar-brand:hover, #footer a:hover {
    color: #dbdbdb;
    text-decoration: underline;
  }

#content table th, #content table td {
  border: 1px solid #cccccc;
  padding: 6px 12px;
  text-align: left;
}

* {
  color: #dbdbdb;
  font-family: "Inconsolata";
  line-height: 1.2;
}

.container {
  max-width: 750px;
}

.navbar-brand {
  font-size: 2rem;
}

#content p {
  margin-bottom: 0.6rem;
}

#content h1, #content h2, #content h3, #content h4, #content h5, #content h6 {
  font-size: medium;
  font-weight: bold;
  margin: 1rem 0 0.6rem 0;
  color: #dbdbdb;
}

#content h1 {
  font-size: 1.8rem;
}

#content h2 {
  font-size: 1.6rem;
}

#content h3 {
  font-size: 1.4rem;
}

#content h4 {
  font-size: 1.2rem;
}

#content img {
  display: block;
  margin: 1rem auto;
  max-width: 100%;
}

#content .avatar > img {
  border-radius: 50%;
  float: right;
  margin: -8px 0 0 16px;
  height: 90px;
  width: 90px;
}

#content ol {
  counter-reset: list;
  list-style: none;
  padding-left: 2rem;
}
  #content ol > li {
    display: table-row;
  }
  #content ol > li:before {
    content: "[" counter(list, decimal) "] ";
    counter-increment: list;
    display: table-cell;
    text-align: right;
    padding-right: .5em;
  }

#content .container > ol, #content .footnotes > ol {
  padding-left: 0;
}

#content ul {
  list-style: inside;
  padding-left: 2rem;
}
  #content ul > li {
    list-style-position: outside;
    margin-left: 1em;
  }

#content .container > ul, #content .footnotes > ul {
  padding-left: 0;
}

#content table {
  margin: 1rem auto;
  width: 100%;
}
  #content table th {
    font-weight: bold;
    background-color: #3d0000;
  }
  #content table tr:nth-child(2n) {
    background-color: #272822;
  }

#content blockquote {
  border-left: 4px solid;
  font-style: italic;
  margin: 1rem 0;
  padding: 8px 8px;
}

#content code {
  color: #dbdbdb;
  background-color: #3d0000;
  border: 1px solid #cccccc;
  border-radius: 10%;
  padding: 0px 4px;
}

#content pre code {
  all: unset;
}

#content .highlight {
  margin: 1rem auto;
}
  #content .highlight > pre {
    padding: 8px 8px;
  }

body {
  background-color: #1d1e20;
}

hr {
  color: #222222;
}

.bg-so-dark {
  background-color: #1d1e20;
  border-color: #1d1e20;
}

